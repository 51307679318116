<template>
    <div id="bodydiv">
        <v-container fluid grid-list-xl pt-0 pb-0 px-3 class="full-height">
            <v-overlay
                :value="loading"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    col-classes="xl12 lg12 md12 sm12 xs12 pt-0"
                >
                    <v-card flat v-if="ZohoDashboards.length == 1">
                        <div>
                            <h2 class="mt-2">{{ $t('message.dashboard') }}</h2>
                            <vue-friendly-iframe :src="ZohoDashboards[0].ZohoDashboard.src" @iframe-load="onIframeLoad()" :style="dynamicStyle" class="iframe-height"></vue-friendly-iframe>
                        </div>
                    </v-card>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "DemoSales",
    data() {
        return {
            loader: true,
            active: null,
            loading: false,
        }
    },
    computed: {
        ...mapGetters('user', {
            User: 'User',
            ZohoDashboards: 'ZohoDashboards'
        }),
        dynamicStyle () {
            return {
                '--spacer-height': this.ZohoDashboards.length > 1 ? '120px' : '95px'
            }
        }
    },
    created() {
        if(this.ZohoDashboards.length === 0) this.loading = false
    },
    methods: {
        onIframeLoad() {
            this.loading = false
        },
        reportMounted() {
            this.loading = false
        }
    }
}
</script>

<style>
.full-height {
    height: calc(100vh - 77px);
}
.iframe-height iframe {
    border: 0;
    height: calc(100vh - var(--spacer-height));
}
.v-tab {
    text-transform: none !important;
}
</style>